/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'gfx-theme.scss' as gfx-theme;
@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.elevation-classes();
@include mat.app-background();

h1 {
	@apply text-2xl;
}
h2 {
	@apply text-xl;
}
h3 {
	@apply text-lg;
}
a {
	@apply text-gfx-primary;
	@apply hover:cursor-pointer;
}

.dark a {
	@apply text-gfx-dark-primary;
}

:root {
	@include mat.all-component-themes(gfx-theme.$light-theme);
}

.dark {
	@include mat.all-component-colors(gfx-theme.$dark-theme);
}

.gfx-button-primary {
	@include mat.button-color(gfx-theme.$light-theme, $color-variant: primary);
}

.dark .gfx-button-primary {
	@include mat.button-color(gfx-theme.$dark-theme, $color-variant: primary);
}

.gfx-button-secondary {
	@include mat.button-color(gfx-theme.$light-theme, $color-variant: secondary);
}

.dark .gfx-button-secondary {
	@include mat.button-color(gfx-theme.$dark-theme, $color-variant: secondary);
}

.gfx-button-error {
	@include mat.button-color(gfx-theme.$light-theme, $color-variant: error);
}

.ba-color {
	color: #fec424;
}

.bb-color {
	color: #cadf87;
}

.bc-color {
	color: #817b00;
}

.bp-color {
	color: #fe0000;
}

html body {
	height: 100%;
}

/* the real use-case behind this was that I couldn't figure
	out a way for the contents of the auto-complete search
	options to take up the full width of the <mat-select> area */
.mdc-list-item__primary-text {
	width: 100%;
}

/* An empty div to reserve space for a progress bar, so that content doesn't move around */
.reserved-space {
	height: var(--mdc-linear-progress-track-height, 4px);
}

.dialog-form-grid {
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	overflow: auto;
}

.dialog-form-grid > .mat-mdc-dialog-content {
	max-height: 100%;
}
